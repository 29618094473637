import React from "react";
import PropTypes from "prop-types";

const SkillShare = ({ children, center }) => {
    return (
        <ul
            className={
                "skill-share d-flex liststyle" +
                (center ? " justify-content-center" : "")
            }
        >
            {children}
        </ul>
    );
};

SkillShare.propTypes = {
    children: PropTypes.node.isRequired,
    center: PropTypes.bool,
};

export { default as SkillItem } from "./skill-item";

export default SkillShare;
