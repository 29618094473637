import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "@components/section-title";
import SkillShare, { SkillItem } from "@ui/skill-share";
import { SectionTitleType, SkillType } from "@utils/types";

const ExperienceArea = ({ data }) => {
    return (
        <div
            id="skills"
            className="rn-experience-area section-separator rn-section-gap slide"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {data?.section_title && (
                            <SectionTitle
                                {...data.section_title}
                                className="text-center"
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-delay="100"
                                data-aos-once="true"
                            />
                        )}
                    </div>
                </div>
                <div className="row mt--10">
                    <div className="col-12 col-md-10 col-lg-8 m-auto mt_mobile--30">
                        {data?.skills && (
                            <div className="skill-share-inner pt-5">
                                <SkillShare center>
                                    {data.skills.map((skill) => (
                                        <SkillItem
                                            key={skill.id}
                                            image={skill.image}
                                            tooltip={skill.title}
                                        />
                                    ))}
                                </SkillShare>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

ExperienceArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        skills: PropTypes.arrayOf(PropTypes.shape(SkillType)),
    }),
};

export default ExperienceArea;
