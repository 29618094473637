import React from "react";
import SectionTitle from "@components/section-title";

const AboutMeArea = ({ data }) => {
    return (
        <div
            id="experiences"
            className="rn-experience-area section-separator rn-section-gap"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {data?.section_title && (
                            <SectionTitle
                                {...data.section_title}
                                className="text-center"
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-delay="100"
                                data-aos-once="true"
                            />
                        )}
                    </div>
                </div>
                <div className="row mt--40 justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-8">
                        {data?.texts?.map((text, index) => (
                            <p
                                style={{ fontSize: "1.8rem" }}
                                key={"about-text-" + index}
                            >
                                {text.content}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutMeArea;
