import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Button from "@ui/button";
import Image from "@ui/image";
import { ImageType, TextType } from "@utils/types";

const SkillCard = ({
    timeRange,
    title,
    subtitle,
    desc,
    texts,
    path,
    cta,
    image,
    className,
    ...restProps
}) => {
    return (
        <div className={cn("experience-style-two", className)} {...restProps}>
            <div className="experience-left">
                {image?.src && (
                    <div className="experience-image">
                        <Image src={image.src} alt={image?.alt || title} />
                    </div>
                )}

                <div className="experience-center">
                    {timeRange && <span className="date">{timeRange}</span>}
                    {title && <h4 className="experience-title">{title}</h4>}
                    {subtitle && <h6 className="subtitle">{subtitle}</h6>}
                    {texts &&
                        texts.map((text, index) => (
                            <p
                                className="disc"
                                key={`dist-${index}`}
                                style={{ marginBottom: 20 }}
                                dangerouslySetInnerHTML={{
                                    __html: text.content,
                                }}
                            ></p>
                        ))}
                </div>
            </div>
            {path ? (
                <div className="experience-right">
                    <div className="experience-footer">
                        <Button path={path}>{cta || "CONTACT ME"}</Button>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

SkillCard.propTypes = {
    timeRange: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    desc: PropTypes.string,
    cta: PropTypes.string,
    path: PropTypes.string,
    image: PropTypes.shape(ImageType),
    className: PropTypes.string,
};

export default SkillCard;
