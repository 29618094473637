import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import Image from "@ui/image";
import { ImageType } from "@utils/types";

const SkillItem = ({ image: { src, alt }, tooltip }) => {
    return (
        <>
            <li data-tip={tooltip}>
                <Image src={src} alt={alt || "skill"} />
            </li>
            {tooltip ? (
                <ReactTooltip
                    place="top"
                    type="light"
                    effect="solid"
                ></ReactTooltip>
            ) : (
                ""
            )}
        </>
    );
};

SkillItem.propTypes = {
    image: PropTypes.shape(ImageType).isRequired,
    tooltip: PropTypes.string,
};

export default SkillItem;
